import React from 'react';
import PropTypes from 'prop-types';
import Page from './Page';
import RichText from '../Shared/RichText';

const RichTextPage = (props) => {
	const { content } = props.pageContext;
	
	return (
		<Page {...props}>
			<RichText content={content} />
		</Page>
	)	;
};


RichTextPage.propTypes = {
	pageContext: PropTypes.shape({
		content: PropTypes.object.isRequired
	}).isRequired
};

export default RichTextPage;
